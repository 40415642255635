.header{
    width: 100%;
    height: 3.5rem;
    background-color: #E63626;
    /* background-image: url('../images/logo.png'); */
	box-shadow: 0 1px 32px 3px rgba( 212, 212, 212, 1 );
	/* backdrop-filter: blur( 4px ); */
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-position: 3.5rem 50%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
}
