html, body {
    padding: 0;
    margin: 0;
    height: 100%;
	width: 100%;
}

body {

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
    height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#main-content-container{
    height: 100%;
	width: 100%;
    padding-top: 6rem;
}

#main-container{
	background: #fff;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}


.main-content{
    height: 90%;
	 width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
	 margin-top: 1rem;
}


.w66{
    width: 77%;
}


.less-top{
    margin-top: -3rem;
}




.small-icon {
    max-height: 1.5rem;
    max-width: 1.5rem;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	background-color: #B40404;
}
